// extracted by mini-css-extract-plugin
export var headerContainer = "items-module--headerContainer--2UdBO";
export var tabsContainer = "items-module--tabsContainer--gtaIs";
export var productsContainer = "items-module--productsContainer--2VAyU";
export var productTitle = "items-module--productTitle--3bC6R";
export var description = "items-module--description--323IY";
export var descriptionContainer = "items-module--descriptionContainer--kbnsY";
export var imageContainer = "items-module--imageContainer--uCO99";
export var tabIndicator = "items-module--tabIndicator--3ZRji";
export var tab = "items-module--tab--3LjT2";
export var fab = "items-module--fab--2ZEOv";
export var displayNone = "items-module--displayNone--3MN6-";
export var collectionFilters = "items-module--collectionFilters--3-c56";
export var filterSelect = "items-module--filterSelect--4smn-";
export var formLabel = "items-module--formLabel--2Qrup";
export var formSelect = "items-module--formSelect--3AN_G";
export var selectItem = "items-module--selectItem--1Hfaa";
export var tabs = "items-module--tabs--NJxh8";