import React, { useState } from 'react';
import { useStaticQuery, graphql } from "gatsby";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Hidden from '@material-ui/core/Hidden';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { navigate, useLocation } from "@reach/router";
import GlobalLayout from "../../layouts/globalLayout";
import * as styles from './items.module.css';
import { ImFilter } from 'react-icons/im';
import { Fab, Grid } from "@material-ui/core";
import RentalItemGrid from "../../components/RentalItemGrid/rentalItemGrid";
import { useTopMostDiv } from "../../components/helperHooks";
import { getUniqueCollections } from "../../utils/shopifyUtils";

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

export default function Items() {
    const { allShopifyCollection } = useStaticQuery(
        graphql`
            query {
                allShopifyCollection(filter: {products: {elemMatch: {tags: {eq: "rent"}}}}) {
                    nodes {
                        title
                        handle
                    }
                }
            }
        `);

    const [tabDrawerOpen, setTabDrawerOpen] = useState(false);

    const collections = getUniqueCollections(allShopifyCollection.nodes ? allShopifyCollection.nodes.map(collection => collection) : []);

    const topmostIndex = useTopMostDiv(collections.map(collection => collection.handle));
    const url = useLocation();

    const handleChange = (event, newValue) => {
        navigate(`/rentals/items#${collections[newValue].handle}`);
    };

    const tabsContainer = (
        <Tabs
            orientation="vertical"
            variant="scrollable"
            value={topmostIndex}
            onChange={handleChange}
            aria-label="Rental Items Menu"
            className={styles.tabs}
            TabIndicatorProps={{
                className: styles.tabIndicator
            }}
        >
            {
                collections.map((tag, index) => (
                    <Tab key={tag.handle} label={tag.title} {...a11yProps(index)} className={styles.tab} />
                ))
            }
        </Tabs>
    );

    return (
        <GlobalLayout>
            <Grid container>
                <Hidden smDown>
                    <Grid item xs={2} className={styles.tabsContainer}>
                        {tabsContainer}
                    </Grid>
                </Hidden>
                <Hidden mdUp>
                    <Fab onClick={() => { setTabDrawerOpen(!tabDrawerOpen); }} className={styles.fab}>
                        <ImFilter />
                    </Fab>
                    <SwipeableDrawer className={styles.tabsDrawer} anchor="left" open={tabDrawerOpen} onClose={() => { setTabDrawerOpen(false); }}>
                        {tabsContainer}
                    </SwipeableDrawer>
                </Hidden>
                <Grid item md={8} xs={12} className={styles.productsContainer}>
                    <RentalItemGrid />
                </Grid>
            </Grid>
        </GlobalLayout>
    );
}