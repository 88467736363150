import React, { useContext } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import * as styles from './rentalItemGrid.module.css';
import PictureCardWithBorder from '../PictureCardWithBorder/pictureCardWithBorder';
import { getPrice, getUniqueCollections } from '../../utils/shopifyUtils';
import { WishlistContext } from '../../provider/WishlistProvider';

const RENTAL_COLLECTION_HANDLES = ["furniture", "decor", "rugs", "table-top", "all-1"];

const collectionSort = {
  "table-top": (product1, product2) => {
    const glassGroup = (product) => {
      return product.handle && (product.handle.toLowerCase().includes("glasses") || product.handle.toLowerCase().includes("tumblers"));
    }

    const silverWareGroup = (product) => {
      return product.handle && (product.handle.toLowerCase().includes("candlesticks") 
        || product.handle.toLowerCase().includes("servers")
        || product.handle.toLowerCase().includes("knives")
        || product.handle.toLowerCase().includes("forks")
        || product.handle.toLowerCase().includes("spoons"));
    }

    if (glassGroup(product1)) {
      return -1;
    } else if (glassGroup(product2)) {
      return 1;
    } else if (silverWareGroup(product1)) {
      return -1;
    } else if (silverWareGroup(product2)) {
      return 1;
    }

    return 0;
  },
  default: (a, b) => {
    return 0;
  }
}

const RentalItemGrid = () => {
  const { allShopifyCollection } = useStaticQuery(
    graphql`{
  allShopifyCollection {
    edges {
      node {
        description
        title
        handle
        products {
          id
          title
          handle
          createdAt
          tags
          images {
            id
            originalSrc
            gatsbyImageData(width: 910, layout: CONSTRAINED)
          }
          variants {
            price
            id
            shopifyId
            legacyResourceId
          }
          totalInventory
        }
      }
    }
  }
}
`
  )

  const collections = getUniqueCollections(allShopifyCollection.edges ? allShopifyCollection.edges.map(collection => collection.node) : [], RENTAL_COLLECTION_HANDLES);

  return (
    <div className={styles.container}>
      {collections.map(
          ({
            description,
            title,
            products,
            handle
          }) => (
            <Collection key={handle} handle={handle} description={description} title={title} products={products} id={handle} />
          )
        )}
    </div>
  )
}

export default RentalItemGrid;

const Collection = ({
  description,
  title,
  products,
  handle
}) => {
  const sortFunc = collectionSort[handle] ? collectionSort[handle] : collectionSort.default;

  return (
    <div className={styles.collection}>
      <span className={styles.anchor} id={handle}></span>
      <h1 className={styles.collectionTitle}>{title}</h1>
      <p className={styles.collectionDescription}>{description}</p>
      <div className={styles.grid}>
        {
          products.sort(sortFunc).map(product => (<Product key={product.handle} {...product} />))
        }
      </div>
    </div>
  )
}

const Product = (product) => {
    const {
        id,
        handle,
        title,
        images: [firstImage, secondImage],
        variants: [firstVariant]
    } = product;

  const {
    addItemToWishlist,
    setIsOpen,
    wishlist
  } = useContext(WishlistContext);

  const itemInWishlist = wishlist.lineItems.find(item => item.handle === handle);
  
  return (
    <div className={styles.product} key={id}>
      <PictureCardWithBorder
        image={firstImage ? firstImage.gatsbyImageData : undefined}
        image2={secondImage ? secondImage.gatsbyImageData : undefined}
        buttonText={"ADD TO WISHLIST"}
        buttonDisabled={itemInWishlist}
        buttonTextDisabled={"ALREADY IN WISHLIST"}
        href={`/rentals/items/${handle}`}
        text={title}
        subText={[getPrice(firstVariant.price)]}
        buttonClick={() => {
          if (!itemInWishlist) {
            addItemToWishlist(product);
            setIsOpen(true);
          }
        }}
      />
    </div>
  );
}